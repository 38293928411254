// ページ内アンカーリンクのスクロールエフェクト
// ヘッダーが固定の場合はheaderHightにヘッダーの高さを設定(PC/SP要設定)
jQuery(function($) {
    var headerHight = 0; // 固定ヘッダの高さ
    $('a[href^="#"]').click(function(){
        var href= $(this).attr("href");
        var target = $(href == "#" || href == "" ? 'html' : href);
        if ( target.length > 0 ) {
            var position = target.offset().top - headerHight;
            $("html, body").animate({scrollTop:position}, 550, "swing");
            return false;
        }
    });
})

// ブログサムネイル 中心でトリミング
jQuery(function($) {
    if ( $('.obj_image').length > 0 ) {
        objectFitImages('.obj_image');
    }
});


// ページトップボタンの表示
jQuery(function($) {
	// 該当なしでも
	if ( $('#pageup').length > 0 ) {
		var topBtn = $('#pageup');    
		
		//スクロールしてトップ
		topBtn.click(function () {
			$('body,html').animate({
				scrollTop: 0
			}, 500);
			return false;
		});
	}
});




// 投稿一覧のリンク表示
jQuery(function($) {
    // お気に入りのHTML生成でもう一度使いたいので、関数化します。
    do_thema_list_article_grid();
});

// 投稿一覧のリンク表示
function do_thema_list_article_grid()
{
    var $ = jQuery;
    
    // 該当なしでも
    if ( $('.list_article_grid .hover_box').length > 0 ) {
        var supportTouch = 'ontouchend' in document;

        if (supportTouch) {		// タッチ
            $(document).on("click", '.list_article_grid .thum', function(e) {
                e.stopPropagation();
                dispLinkBox($(this).find('.hover_box'));
            });
            $(document).on("click", '.list_article_grid .hover_box', function(e) {
                e.stopPropagation();
                hideLinkBox($(this));
            });
        } else {	// マウス
            $(document).on("mouseenter", '.list_article_grid .thum', function() {
                dispLinkBox($(this).find('.hover_box'));
            });
            $(document).on("mouseleave", '.list_article_grid .thum', function() {
                hideLinkBox($(this).find('.hover_box'));
            });
        }

        // 表示
        function dispLinkBox($this) {
            $this.css({"visibility": "visible", "opacity": "1"});
        }
        // 非表示
        function hideLinkBox($this) {
            $this.css({"opacity": "0"});
            setTimeout(function() {
                $this.css({"visibility": "hidden"});
            }, 400);
        }
    }
}


// Aboutページの図形の固定制御
jQuery(function($) {
	if ($('.about_contents_box .about_figure').length > 0) {
		var CLASS_STOP = "about_bottom";
		var bottomPos;
		var $stage = $('.about_contents_box');
		var $fig = $('.about_contents_box .about_figure');
		var ww;
		var wh;
		var scroll;
		var flg = false;
		var setEvent = false;

		reset();

		if ($(window).width() > 768) {
			checkScroll();
			$(window).on("scroll", checkScroll);
			setEvent = true;
		}

		$(window).on("resize", function() {
			if ($(window).width() !== ww) {
				reset();
				if (!setEvent && $(window).width() > 768) {
					$(window).on("scroll", checkScroll);
					setEvent = true;
				} else if (setEvent && $(window).width() <= 768) {
					$(window).off("scroll", checkScroll);
					setEvent = false;
				}
			}
		});

		function reset() {
			ww = $(window).width();
			wh = $(window).height();
			// bottomPos = $stage.innerHeight() - ( wh / 2 - $fig.innerHeight() / 2 ) - wh;
			bottomPos = $stage.innerHeight() - wh;
		}

		function checkScroll() {
			scroll = $(window).scrollTop();
			if (!flg && bottomPos <= scroll) {
				$fig.addClass(CLASS_STOP);
				flg = true;
			} else if (flg && bottomPos > scroll) {
				$fig.removeClass(CLASS_STOP);
				flg = false;
			}
		}
	}
});

// サイトスライダー
jQuery(function($) {
	if ( $('#slider_site').length > 0 ) {

		// カルーセルスライダーの設定
		var options = {
			arrows: false,
			infinite: true,
			autoplaySpeed: 0,	//　スライダー表示時間
			speed: 10000,			// スライド変化時間
			dots: false,
			autoplay: true,
			pauseOnFocus: false,		// スライダーにフォーカスしたらオートプレイを停止
			pauseOnHover: false,		// スライダーにマウスオーバーでオートプレイを停止
			pauseOnDotsHover: false,	// ドットナビゲーションにフォーカスしたらオートプレイを停止
			slidesToShow: 3.5,
			cssEase: "linear",
			responsive: [
				{
				breakpoint: 1001,
				settings: {
					slidesToShow: 3,
				}
				},
				{
				breakpoint: 769,
				settings: {
					slidesToShow: 2.5,
				}
				},
				{
				breakpoint: 569,
				settings: {
					slidesToShow: 1.7,
				}
				},
				{
				breakpoint: 415,
				settings: {
					slidesToShow: 1.2,
				}
				}
			],
		}

		$('#slider_site').slick(options);

	}
});

// コメントスライダー
jQuery(function($) {

	if ( $('.comment_slider').length > 0 ) {

		var INTERVAL_DELAY = 1333;

		// フェードスライダーの設定
		var options = {
			autoplay: true,
			arrows: false,
			infinite: true,
			autoplaySpeed: 3999,	//　スライダー表示時間
			speed: 700,			// スライド変化時間
			dots: false,
			fade: true,
			pauseOnFocus: false,		// スライダーにフォーカスしたらオートプレイを停止
			pauseOnHover: false,		// スライダーにマウスオーバーでオートプレイを停止
			pauseOnDotsHover: false,	// ドットナビゲーションにフォーカスしたらオートプレイを停止
		}

		// 【起動】1列目のコメントスライダー＆TODAY'S ちょうどいい
		$('.pickup_today .comment_slider, .grid_item:nth-child(3n-2) .comment_slider').slick(options);
		
		// 【起動】2列目のコメントスライダー
		setTimeout(function() {
			$('.grid_item:nth-child(3n-1) .comment_slider').slick(options);
		}, INTERVAL_DELAY);

		// 【起動】3列目のコメントスライダー
		setTimeout(function() {
			$('.grid_item:nth-child(3n) .comment_slider').slick(options);
		}, INTERVAL_DELAY * 2);
	}
});

// 固定背景のスクロール時のズレ対応（IEのみ）
jQuery(function($) {

	if(navigator.userAgent.match(/MSIE 10/i) || navigator.userAgent.match(/Trident\/7\./) || navigator.userAgent.match(/Edge\/12\./)) {
		$('body').on("mousewheel", function (e) {
		e.preventDefault();
		var wd = event.wheelDelta;	// eventをeにしてはダメ！
		var csp = window.pageYOffset;
		window.scrollTo(0, csp - wd);
		});
	}

});

// TODAY'S ちょうどいいのアイコンランダム表示
jQuery(function($) {
	var num = 10,
    randNum = Math.floor(Math.random() * (num)) + 1;
	$('.date_pickup').addClass('random_icon' + randNum);
});



// ----------------------------------------------------
// 		「よせがきを編集する」画面
// ----------------------------------------------------
jQuery(function($) {
	if (document.querySelector('.editMessageWrapper')) {

		// --- 削除 ---
		document.querySelectorAll('.editMessageDelete').forEach(del => {
			del.addEventListener('click', (e) => {
				e.preventDefault();
				
				swal({
					title: 'メッセージを削除しますか？',
					text: 'この動作は戻れません。',
					icon: 'warning',
					buttons: true,
					dangerMode: true,
				}).then((willDelete) => {
					if (willDelete) {
						// 単純にDOM除去
						e.target.closest('.editMessageWrapper').remove();
					} else {
						//swal("Your imaginary file is safe!");
					}
				});
			});
		});

		// --- 並び替え ---
		document.querySelectorAll('.editMessageUp').forEach(button => {
			button.addEventListener('click', (e) => {
				e.preventDefault();
				exchangePosition(true, e.target.closest('.editMessageWrapper'));
			}, false);
		});
		document.querySelectorAll('.editMessageDown').forEach(button => {
			button.addEventListener('click', (e) => {
				e.preventDefault();
				exchangePosition(false, e.target.closest('.editMessageWrapper'));
			}, false);
		});

		/**
		 * ブロック配置変更
		 * @param {boolean} direction - 入れ替え方向。up は true, down は false
		 */
		function exchangePosition(direction, block) {
			if (direction) {
				block.parentNode.insertBefore(block, block.previousElementSibling);
			} else {
				block.parentNode.insertBefore(block.nextElementSibling, block);
			}
			setButtonDisabled();
		}

		/**
		 * 最上＆最下ブロッグのup/downボタンをdisabledに設定
		 */
		function setButtonDisabled() {
			const wrappers = document.querySelectorAll('.editMessageWrapper');
			let up, down
			for (let i = 0; i < wrappers.length; i++) {
				up = wrappers[i].querySelector('.editMessageUp');
				down = wrappers[i].querySelector('.editMessageDown');
				// upボタンの設定
				if (i === 0 && !up.disabled) {
					up.disabled = true;
				} else {
					if (up.disabled) {
						up.disabled = false;
					}
				}
				// downボタンの設定
				if (i === wrappers.length - 1 && !down.disabled) {
					down.disabled = true;
				} else {
					if (down.disabled) {
						down.disabled = false;
					}
				}
			}
		}
		setButtonDisabled();

		// --- ファイル削除 ---
		// const SRC_NO_IMAGE = '/assets/img/common/noimage.jpg';	// 削除時のデフォルト画像パス
		document.querySelectorAll('.fileLoaderDelete').forEach(del => {
			del.addEventListener('click', (e) => {
				e.preventDefault();
				swal({
					title: 'アイコン画像を削除しますか？',
					text: 'この動作は戻れません。',
					icon: 'warning',
					buttons: true,
					dangerMode: true,
				}).then((willDelete) => {
					if (willDelete) {
						// 画像を削除
						e.target.closest('.fileLoaderWrapper').remove();
						// // ファイルローダーのvalueを削除
						// e.target.closest('.fileLoaderWrapper').querySelector('.fileLoaderInput').value = "";
						// // プレビューをデフォルト画像に変更
						// e.target.closest('.fileLoaderWrapper').querySelector('.fileLoaderPreview').src = SRC_NO_IMAGE;
					} else {
						//swal("Your imaginary file is safe!");
					}
				});
			});
		}, false);


		// --- ファイルアップローダー ---
		document.querySelectorAll('.fileLoaderWrapper').forEach(wrapper => {
			new FileDrag(
				wrapper,
				wrapper.querySelector('.fileLoaderInput'),
				['jpg', 'png', 'gif', 'webp'],
				wrapper.querySelector('.fileLoaderPreview'),
				null,
				wrapper.querySelector('.fileLoaderPreview')
			)
		});

	}
});


/**
 * ファイルアップロード（ドラッグ＆ドロップ対応）
 */
class FileDrag {
    constructor(zone, input, allowTypes, buttonFileSelect, submit, preview) {
        this.zone = zone;
        this.input = input;
        this.allowTypes = allowTypes;
        this.buttonFileSelect = buttonFileSelect;
		this.submit = submit ? submit : null;
		this.preview = preview ? preview : null;
        
        this.init();
    }
    init() {
        this.setStyleDragover();
        this.clearStyleDragover();
        this.setFileInputWithDrop();
        this.setFileInputWithExplore();
        this.setSelectedFile();
        this.setSubmitClickable();
    }
    // ドラッグしてzoneに入ったらzoneの色を変える
    setStyleDragover() {
        this.zone.addEventListener('dragover', function(e) {
            e.stopPropagation();
            e.preventDefault();
            if (!e.target.classList.contains('dragover')) {
                e.target.classList.add('dragover');
            }
            
        }, false);
    }
    // ドラッグがzoneから外れたらzoneの色を戻す
    clearStyleDragover() {
        this.zone.addEventListener('dragleave', function(e) {
            e.stopPropagation();
            e.preventDefault();
            if (e.target.classList.contains('dragover')) {
                e.target.classList.remove('dragover');
            }
        }, false);
    }
    // ドロップ時にファイルをinputに設定
    setFileInputWithDrop() {
        const self = this;
        this.zone.addEventListener('drop', function(e) {
            e.stopPropagation();
            e.preventDefault();
            e.target.classList.remove('dragover');
            const files = e.dataTransfer.files; //ドロップしたファイルを取得
            if (self.checkDropFiles(files)) {
                self.input.files = files;   // inputにファイルを設定
				self.setPreview(URL.createObjectURL(files.item(0)));	// プレビュー画像を設定
            } else {
				if (self.preview) {
					self.input.value = self.preview.src;
				} else {
					self.input.value = "";
				}
            }
            return false;
        }, false);
    }
    // 設定されたファイルのチェック
    checkDropFiles(files) {
        if (files.length > 1) {
            this.dispErrorMsg('複数画像エラー');
            this.setSubmitClickable(false);
            return false;
        }
        const ext = files.item(0).name.split('.').pop();
        if (ext && this.allowTypes.includes(ext)) {
            // this.dispMsg('選択ファイル：' + files.item(0).name);
            this.setSubmitClickable(true);
            return true;
        } else {
            this.dispErrorMsg(`画像形式エラー[${this.allowTypes.toString()}]`);
            this.setSubmitClickable(false);
            return false;
        }
    }
    // エクスプローラーからファイル設定
    setFileInputWithExplore() {
        const self = this;
        this.buttonFileSelect.addEventListener('click', function() {
            self.input.click();
        }, false);
    }
    // ファイルのインプット要素に変更があったら
    setSelectedFile() {
        const self = this;
        this.input.addEventListener('change', function(e) {
            e.preventDefault();
            const files = e.target.files; //ドロップしたファイルを取得
            if (!self.checkDropFiles(files)) {
                if (self.preview) {
					self.input.value = self.preview.src;
				} else {
					self.input.value = "";
				}
            } else {
				self.setPreview(URL.createObjectURL(files.item(0)));	// プレビュー画像を設定
			}
        }, false);
    }
    // エラー文表示
    dispErrorMsg(msg) {
        this.clearMsg();

        const errorP = document.createElement('p');
        errorP.style.cssText = "color: #f00; margin-top: 5px; font-size: 10px;";
        errorP.textContent = msg;
        errorP.classList.add('errMsg');
        this.zone.appendChild(errorP);
        
        // setTimeout(function() {
        //     self.zone.removeChild(errorP);
        // }, 5000);
    }
    // メッセージ文表示
    // dispMsg(msg) {
    //     this.clearMsg();

    //     const msgP = document.createElement('p');
    //     msgP.style.cssText = "margin-top: 5px;";
    //     msgP.textContent = msg;
    //     msgP.classList.add('fileMsg');
    //     this.zone.appendChild(msgP);
    // }
    // メッセージクリア
    clearMsg() {
        if (this.zone.querySelector('.fileMsg')) {
            this.zone.removeChild(this.zone.querySelector('.fileMsg'));
        }
        if (this.zone.querySelector('.errMsg')) {
            this.zone.removeChild(this.zone.querySelector('.errMsg'));
        }
    }
    // サブミットボタンを有効にする
    setSubmitClickable(boolean) {
		if (this.submit) {
			if (boolean == null) {
				if (!this.input.value) {
					this.submit.disabled = true;
				} else {
					this.submit.disabled = false;
				}
			} else {
				this.submit.disabled = !boolean;
			}
		}
        
        
        
    }
	// プレビュー画像を更新
	setPreview(src) {
		const self = this;
		if (self.preview) {
			self.preview.src = src;
		}
	}

}